import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'employer-chanel';
  constructor(){
    let lang = localStorage.getItem('idioma');
    console.log(lang)
    if(lang == null){
      localStorage.setItem('idioma', "esp")
    }
  }
}
