<mat-card class="card-primary" *ngIf="lang == 'esp' ">

    <mat-card-content>
        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <h2>Muchas gracias por participar</h2>
        <p>Y si quieres, en el siguiente link puedes acceder al <a href="https://go-fit.es/tcv/test"> Test de Capacidad Vital</a>, que te ofrece
            un
            informe exclusivo de GO fit LAB sobre tu nivel de energía y capacidad.</p>
    </mat-card-content>
</mat-card>



<mat-card class="card-primary" *ngIf="lang == 'por' ">

    <mat-card-content>


        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <h2>Muito obrigado por participar.</h2>
        <p>E se quiser, no link a seguir você pode acessar o <a href="https://go-fit.pt/tcv/test"> Test de Capacidad Vital</a>, que oferece um
            relatório exclusivo do GO fit LAB sobre seu nível de energia e capacidade.</p>
    </mat-card-content>
</mat-card>
<mat-card class="card-primary" *ngIf="lang == 'eng' ">

    <mat-card-content>
        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <h2>Thank you very much for participating</h2>
        <p>If you want, in the following link you can access the <a href="#"> Vital Capacity Test</a>, which offers you an exclusive report from GO fit LAB on your energy level and capacity.</p>
    </mat-card-content>
</mat-card>