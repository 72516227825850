import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { StepComponent } from './step/step.component';
import { ThanksPromotorComponent } from './thanks-promotor/thanks-promotor.component';
import { ThanksComponent } from './thanks/thanks.component';

const routes: Routes = [
  {
    path: 'survey',
    component: StepComponent,
  },
  {
    path: 'step',
    component: StepComponent,
  },
  {
    path: 'thanks',
    component: ThanksComponent,
  },
  {
    path: 'thanks-promotor',
    component: ThanksPromotorComponent,
  },
  { path: '**', redirectTo: '/survey' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
