import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../services/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
	selector: "app-step",
	templateUrl: "./step.component.html",
	styleUrls: ["./step.component.scss"],
})
export class StepComponent implements OnInit {
	asks = [];
	commit = "";
	recomendation;
	respLow = "";
	respMid = "";
	respHigth = "";
	escala;
	objective = [
		"Control de peso.",
		"Estar en forma, saludable.",
		"Fuerza y definición.",
		"Rendimiento deportivo.",
	];
	objective1 = [
		"controlo do peso.",
		"Estar em forma, saudável.",
		"Força e definição.",
		"Desempenho esportivo.",
	];

	objective2 = [
		"Weight management.",
		" To be fit, healthy.",
		" Strength and definition.",
		"Sports Performance.",
	];
	questions;
	opcion = "";
	respZone = [];
	respZoneFit = [];
	respObj = [];
	low = false;
	mid = false;
	higth = false;
	selectRecomendation: string;
	optionRecomendation: string[] = ["Si", "no"];
	optionRecomendation1: string[] = ["Yes", "no"];
	selectObjetive: string;

	zone = [
		{
			id: 1,
			text: "Sala fitness: fuerza y peso libre",
			isChecked: false,
			idioma: "esp",
		},
		{ id: 2, text: "Sala cardio", isChecked: false, idioma: "esp" },
		{ id: 3, text: "Actividades dirigidas", isChecked: false, idioma: "esp" },
		{ id: 4, text: "Piscina", isChecked: false, idioma: "esp" },
		{ id: 5, text: "Zona Spa", isChecked: false, idioma: "esp" },
		{ id: 6, text: "Pistas de pádel / tenis", isChecked: false, idioma: "esp" },
		{ id: 7, text: "En el exterior", isChecked: false, idioma: "esp" },
		{ id: 8, text: "Otras salas", isChecked: false, idioma: "esp" },
		{
			id: 1,
			text: "Sala de fitness: força e peso livre",
			isChecked: false,
			idioma: "por",
		},
		{ id: 2, text: "sala de cardio", isChecked: false, idioma: "por" },
		{ id: 3, text: "Aulas de grupo", isChecked: false, idioma: "por" },
		{ id: 4, text: "Piscina", isChecked: false, idioma: "por" },
		{ id: 5, text: "Área de spa", isChecked: false, idioma: "por" },
		{ id: 6, text: "Aulas de paddle / tênis", isChecked: false, idioma: "por" },
		{ id: 7, text: "No exterior", isChecked: false, idioma: "por" },
		{ id: 8, text: "outros quartos", isChecked: false, idioma: "por" },
		{
			id: 1,
			text: "Fitness room: strength and free weight",
			isChecked: false,
			idioma: "eng",
		},
		{ id: 2, text: "cardio room", isChecked: false, idioma: "eng" },
		{ id: 3, text: "Guided activities", isChecked: false, idioma: "eng" },
		{ id: 4, text: "Pool", isChecked: false, idioma: "eng" },
		{ id: 5, text: "Spa area", isChecked: false, idioma: "eng" },
		{ id: 6, text: "Padel / tennis courts", isChecked: false, idioma: "eng" },
		{ id: 7, text: "Outdoor", isChecked: false, idioma: "eng" },
		{ id: 8, text: "Other rooms", isChecked: false, idioma: "eng" },
	];
	zoneFitness = [
		{
			id: 1,
			text: "Zona de iniciación para el trabajo de fuerza (hay dos TVs de ayuda) (STARTING POINT)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 2,
			text: "Zona de calentamiento y estiramiento. (RECOVERY ZONE)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 3,
			text: "Zona de entrenamiento funcional. (TRAINING EXPERIENCE ZONE)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 4,
			text: "Zona prepara para el entrenamiento de potencia con grandes cargas. (LIFT ZONE",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 5,
			text: "Zona de peso libre. (PESO LIBRE)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 6,
			text: "Zona para el entrenamiento de la aptitud cardio-pulmonar. (ZONA CARDIOVASCULAR)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 7,
			text: "Zona de fuerza con máquinas guiadas de diferentes grupos musculares. (ZONA DE MÁQUINAS SELECTORIZADAS)",
			isChecked: false,
			idioma: "esp",
		},
		{
			id: 1,
			text: "Zona de STARTING POINT para o trabalho de força (existem dois televisores para assistência)",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 2,
			text: "Zona de aquecimento e de alongamentos (RECOVERY ZONE)",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 3,
			text: "Zona de treino funcional (TRAINING EXPERIENCE ZONE)",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 4,
			text: "Zona preparada para o treino de força com pesos pesados (LIFT ZONE)",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 5,
			text: "Zona de pesos livres (PESO LIVRE)",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 6,
			text: "Zona para treino de aptidão cardio-pulmonar (CARDIOVASCULAR ZONE).",
			isChecked: false,
			idioma: "por",
		},
		{
			id: 7,
			text: "Zona de força com máquinas orientadas para diferentes grupos musculares (ÁREA DE MÁQUINAS SELECCIONADAS)",
			isChecked: false,
			idioma: "por",
		},
	];
	lang;
	step = 0;
	loading = true;
	flag;
	survey;
	aux = [];
	aux2 = [];
	showChild = false;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private common: CommonService,
		private _snackBar: MatSnackBar
	) {}

	async home() {
		await this.route.params.subscribe(async (parameter: any) => {
			console.log(parameter);

			if (!parameter.id) {
				this.loading = false;
				this.flag = false;
				console.log("no existe parametro id");
			} else {
				console.log("otra cos");
				// this.loading = false;
				// localStorage.setItem("BQT.token", parameter.id);
				window.sessionStorage.setItem("BQT.token", parameter.id);
				this.common

					.getSurvey("esp")
					.then((res: any) => {
						console.log("por aqui");
						console.log(res);
						if (res.ok === false) {
							console.log("por aqui");
							this.flag = false;
							return;
						}
						console.log("por aqui");

						this.flag = true;
						let auxEscala = res.survey.answers.escala;
						console.log(auxEscala);

						if (auxEscala != 11) {
							this.asks[auxEscala] = auxEscala;
							this.step = 0.5;
							if (auxEscala >= 0 && auxEscala <= 6) {
								this.commit = res.survey.answers.commit;
								this.low = true;
								this.mid = false;
								this.higth = false;
								this.step = 1;
							}

							if (auxEscala >= 7 && auxEscala <= 8) {
								this.commit = res.survey.answers.commit;
								this.mid = true;
								this.low = false;
								this.higth = false;
								this.step = 1;
							}

							if (auxEscala >= 9 && auxEscala <= 10) {
								this.commit = res.survey.answers.commit;
								this.higth = true;
								this.mid = false;
								this.low = false;
								this.step = 1;
							}
						}
						console.log(res.survey.answers.zones);
						let arr = res.survey.answers.zones.split(",");
						for (const a of arr) {
							this.zone.forEach((element) => {
								if (a === element.text) {
									console.log(element);
									element.isChecked = true;
									this.step = 2;
								}
							});
						}

						this.respObj = res.survey.answers.commit;
						this.selectRecomendation = res.survey.answers.recomendacion;
						if (this.selectRecomendation != "") {
							this.step = 3;
						}

						this.selectObjetive = res.survey.answers.objetivo;

						this.loading = false;
						this.flag = true;
						this.survey = res.survey;
						// settear idioma segun pais
						if (this.survey.relationCustomer.pais != undefined) {
							if (this.survey.relationCustomer.pais != "") {
								if (this.survey.relationCustomer.pais == "españa") {
									this.lang = "esp";
									localStorage.setItem("idioma", "esp");
								} else if (this.survey.relationCustomer.pais == "portugal") {
									this.lang = "por";
									localStorage.setItem("idioma", "por");
									this.changeFlag(this.lang);
								}
							} else {
								this.lang = "esp";
								localStorage.setItem("idioma", "esp");
							}
						}
						let ar = [];
						let index = 1;
						let xvalue = null;
						for (let field of res.fields) {
							if (field.type == "numero" || field.type == "escala") {
								xvalue = null;
							}

							if (field.type == "textarea") {
								xvalue = "";
							}

							let json = {
								key: "p" + index,
								name: field.name,
								type: field.type,
								optional: "",
								value: xvalue,
							};
							console.log(json);

							ar.push(json);
							index++;
						}
						console.log(ar);

						this.questions = ar;
						// localStorage.setItem("preguntas", JSON.stringify(ar));
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	}
	startSurvey() {
		console.log("iniciar encuesta");
		this.router.navigate(["/step", { page: 0 }]);
	}

	async ngOnInit() {
		await this.home();
		await this.otro();
	}

	// updateText(i, t) {
	//   console.log(i, t.target.value);
	//   this.asks[2] = {commit:t.target.value};
	// }

	async otro() {
		this.questions = localStorage.getItem("preguntas");
		this.lang = localStorage.getItem("idioma");
		this.questions = JSON.parse(this.questions);
		console.log(this.questions);
		this.zone.sort(() => Math.random() - 0.5);
		this.objective.sort(() => Math.random() - 0.5);
		console.log();
	}

	updateRadio2(field) {
		console.log(field.value);
		this.step = 3;
		this.respObj = field.value;
		let resp = {
			escala: this.escala,
			commit: this.commit,
			zones: this.aux.toString(),
			recomendacion: this.opcion,
			zoneFitness: this.aux2.toString(),
			objetivo: this.respObj,
		};
		console.log(resp);

		this.common.finishSurvey(resp).then((res: any) => {
			if (res.ok == true) {
				if (this.escala > 8) {
					this.router.navigate(["/thanks-promotor"]);
				} else {
					this.router.navigate(["/thanks"]);
				}
			}
		});
	}

	someComplete(id: any) {
		console.log(id);
		this.step = 2;
		if (this.zone == null) {
			return false;
		}
		this.respZone = this.zone.filter((t) => t.isChecked == true);
		console.log(this.respZone);
		const results = this.respZone.filter((item) => item.id === 1);
		if (results) {
			this.showChild = true;
		}

		for (const r of this.respZone) {
			if (r.isChecked == true) {
				this.aux.push(r.text);
			}
		}
		console.log(this.aux);
		this.aux = this.aux.filter((item, index) => {
			return this.aux.indexOf(item) === index;
		});

		let resp = {
			escala: this.escala,
			commit: this.commit,
			zones: this.aux.toString(),
			recomendacion: this.opcion,
			objetivo: this.respObj,
		};
		console.log(resp);

		this.common.noFinishSurvey(resp).then((res: any) => {
			console.log(res);
		});
	}
	someComplete2(id: any) {
		console.log(id);
		this.step = 2;
		if (this.zoneFitness == null) {
			return false;
		}
		this.respZoneFit = this.zoneFitness.filter((t) => t.isChecked == true);
		console.log(this.respZoneFit);

		for (const r of this.respZoneFit) {
			if (r.isChecked == true) {
				this.aux2.push(r.text);
			}
		}
		console.log(this.aux2);
		this.aux2 = this.aux2.filter((item, index) => {
			return this.aux2.indexOf(item) === index;
		});

		let resp = {
			escala: this.escala,
			commit: this.commit,
			zones: this.aux.toString(),
			zoneFitness: this.aux2.toString(),
			recomendacion: this.opcion,
			objetivo: this.respObj,
		};
		console.log(resp);

		this.common.noFinishSurvey(resp).then((res: any) => {
			console.log(res);
		});
	}
	updateCheck(id) {
		console.log(id);
		for (let item of this.zone) {
			if (item.id == id) {
				console.log(item.isChecked);
				if (item.isChecked == true) {
					item.isChecked = false;
					// this.respZone.push(id)
				} else {
					item.isChecked = true;
					// this.respZone = this.respZone.filter((item) => item !== id);
				}
				// console.log(this.respZone);
			}
		}
		// this.asks[3]={zone:this.aux}
	}

	update(i, t) {
		this.step = 0.5;
		console.log(this.step);
		this.asks = [];
		this.asks[i] = t.value;
		console.log(this.asks[i]);
		this.escala = this.asks[i];
		console.log(this.escala);
		if (i >= 0 && i <= 6) {
			this.commit = "";
			this.low = true;
			this.mid = false;
			this.higth = false;
		}

		if (i >= 7 && i <= 8) {
			this.commit = "";
			this.mid = true;
			this.low = false;
			this.higth = false;
		}

		if (i >= 9 && i <= 10) {
			this.commit = "";
			this.higth = true;
			this.mid = false;
			this.low = false;
		}

		console.log(i);
		let resp = {
			escala: i,
			commit: this.commit,
			zones: this.aux.toString(),
      zoneFitness: this.aux2.toString(),
			recomendacion: this.opcion,
			objetivo: this.respObj,
		};

		this.common.noFinishSurvey(resp).then((res: any) => {
			console.log(res);
		});
	}
	// updateText2(i, t) {
	//   console.log(i, t.target.value);
	//   this.asks[5] = {objetivo:t.target.value};
	// }

	// update(index, field) {
	//   console.log(index);
	//   console.log(field.value);
	//   console.log((this.asks[index] = 1));
	//   this.asks[index] = {recomendacionEscala:field.value};
	//   console.log(this.asks[1]);
	// }
	updateRadio(field) {
		console.log(field.value);
		console.log(this.selectRecomendation);
		this.step = 3;
		this.opcion = field.value;

		let resp = {
			escala: this.escala,
			commit: this.commit,
			zones: this.aux.toString(),
			recomendacion: this.opcion,
			objetivo: this.respObj,
		};
		console.log(resp);

		this.common.noFinishSurvey(resp).then((res: any) => {
			console.log(res);
		});
	}

	save() {
		if (this.escala == undefined) {
			alert("Debe seleccionar una escala de recomendacion");
			return;
		}
		console.log(this.respZone);

		let resp = {
			escala: this.escala,
			commit: this.commit,
			zones: this.aux.toString(),
      zoneFitness: this.aux2.toString(),
			recomendacion: this.opcion,
			objetivo: this.respObj,
		};
		console.log(resp);
		this.common.finishSurvey(resp).then((res: any) => {
			if (res.ok == true) {
				if (this.escala > 8) {
					this.router.navigate(["/thanks-promotor"]);
				} else {
					this.router.navigate(["/thanks"]);
				}
			}
		});
	}
	valuechange(evt) {
		this.step = 1;
		console.log(this.step);
	}
	changeFlag(flag) {
		console.log(flag);
		this.lang = flag;
		this.common.getSurvey(flag).then((res: any) => {
			console.log(res);

			if (res.fields.length == 0) {
				if (this.lang == "esp") {
					this._snackBar.open(
						"No existe traduccion para este cuestionario",
						"Ok",
						{
							duration: 2000,
						}
					);
				} else if (this.lang == "eng") {
					this._snackBar.open("There is no translation for this survey", "Ok", {
						duration: 2000,
					});
				} else if (this.lang == "por") {
					this._snackBar.open("There is no translation for this survey", "Ok", {
						duration: 2000,
					});
				}

				return;
			}
			let ar = [];
			let index = 1;
			let xvalue = null;
			for (let field of res.fields) {
				if (field.type == "numero" || field.type == "escala") {
					xvalue = 0;
				}
				if (field.type == "trueFalse") {
					xvalue = false;
				}
				if (field.type == "textarea") {
					xvalue = "";
				}
				if (field.type == "code-nps") {
					xvalue = null;
				}
				let json = {
					key: "p" + index,
					name: field.name,
					type: field.type,
					value: xvalue,
				};
				ar.push(json);
				index++;
				localStorage.setItem("idioma", field.lang);
			}
			localStorage.setItem("preguntas", JSON.stringify(ar));
			this.questions = ar;
		});
	}
}
