import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../services/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	loading = true;
	flag;
	survey;
	lang;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private common: CommonService,
		private _snackBar: MatSnackBar
	) {
		this.lang = localStorage.getItem("idioma");
	}

	async ngOnInit() {
		await this.route.params.subscribe(async (parameter: any) => {
			console.log(parameter);

			if (!parameter.id) {
				this.loading = false;
				this.flag = false;
				console.log("no existe parametro id");
			} else {
				console.log("otra cos");
				// this.loading = false;
				localStorage.setItem("BQT.token", parameter.id);
				window.sessionStorage.setItem("BQT.token", parameter.id);
				this.common
					.getSurvey("esp")
					.then((res: any) => {
						console.log(res);
						this.loading = false;
						this.flag = true;
						this.survey = res.survey;
						// settear idioma segun pais
						if (this.survey.relationCustomer.pais != undefined) {
							if (this.survey.relationCustomer.pais != "") {
								if (this.survey.relationCustomer.pais == "españa") {
									this.lang = "esp";
									localStorage.setItem("idioma", "esp");
								} else if (this.survey.relationCustomer.pais == "portugal") {
									this.lang = "por";
									localStorage.setItem("idioma", "por");
									this.changeFlag(this.lang);
								}
							} else {
								this.lang = "esp";
								localStorage.setItem("idioma", "esp");
							}
						}
						let ar = [];
						let index = 1;
						let xvalue = null;
						for (let field of res.fields) {
							if (field.type == "numero" || field.type == "escala") {
								xvalue = null;
							}

							if (field.type == "textarea") {
								xvalue = "";
							}

							let json = {
								key: "p" + index,
								name: field.name,
								type: field.type,
								optional: "",
								value: xvalue,
							};
							console.log(json);

							ar.push(json);
							index++;
						}
						localStorage.setItem("preguntas", JSON.stringify(ar));
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	}
	startSurvey() {
		console.log("iniciar encuesta");
		this.router.navigate(["/step", { page: 0 }]);
	}

	changeFlag(flag) {
		console.log(flag);
    this.flag = false;
    this.loading = true;
		this.lang = flag;
		this.common.getSurvey(flag).then((res: any) => {
			console.log(res);

			this.loading = false;
			if (res.fields.length == 0) {
				if (this.lang == "esp") {
					this._snackBar.open(
						"No existe traduccion para este cuestionario",
						"Ok",
						{
							duration: 2000,
						}
					);
				} else if (this.lang == "eng") {
					this._snackBar.open("There is no translation for this survey", "Ok", {
						duration: 2000,
					});
				} else if (this.lang == "por") {
					this._snackBar.open("There is no translation for this survey", "Ok", {
						duration: 2000,
					});
				}

				return;
			}
			this.survey = res.survey;
			let ar = [];
			let index = 1;
			let xvalue = null;
			for (let field of res.fields) {
				if (field.type == "numero" || field.type == "escala") {
					xvalue = 0;
				}
				if (field.type == "trueFalse") {
					xvalue = false;
				}
				if (field.type == "textarea") {
					xvalue = "";
				}
				if (field.type == "code-nps") {
					xvalue = null;
				}
				let json = {
					key: "p" + index,
					name: field.name,
					type: field.type,
					value: xvalue,
				};
				ar.push(json);
				index++;
				localStorage.setItem("idioma", field.lang);
			}
      this.flag = true;
			localStorage.setItem("preguntas", JSON.stringify(ar));
		});
	}
}
