<div class="langs">
  <div class="flag" (click)="changeFlag('esp')">
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Bandera_de_Espa%C3%B1a_%28nuevo_dise%C3%B1o%29.svg"
      alt=""
    />
  </div>
  <div class="flag" (click)="changeFlag('por')">
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/1200px-Flag_of_Portugal.svg.png"
      alt=""
    />
  </div>
  <div class="flag" (click)="changeFlag('eng')">
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_United_Kingdom.svg/2560px-Flag_of_the_United_Kingdom.svg.png"
      alt=""
    />
  </div>
</div>
<mat-card class="card-primary" *ngIf="!flag" style="background-color: white">
  <mat-card-content *ngIf="lang == 'esp'">
    <img
      src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
      alt="logo Gofit"
      class="logo"
    />
    <h2 style="text-align: center">
      Esta encuesta ya no se encuentra disponible
    </h2>
  </mat-card-content>
  <mat-card-content *ngIf="lang == 'eng'">
    <img
      src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
      alt="logo Gofit"
      class="logo"
    />
    <h2 style="text-align: center">This survey is no longer available</h2>
  </mat-card-content>
  <mat-card-content *ngIf="lang == 'por'">
    <img
      src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
      alt="logo Gofit"
      class="logo"
    />
    <h2 style="text-align: center">Esta pesquisa não está mais disponível</h2>
  </mat-card-content>
</mat-card>
<mat-card
  *ngIf="flag"
  class="card-primary mat-elevation-z"
  style="background-color: white"
>
  <mat-card-content>
    <img
      src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
      alt="logo Gofit"
      class="logo"
    />
    <div class="question" *ngFor="let q of questions; index as i">
      <div class="ask {{ q.type }}" *ngIf="q.type == 'escala' && step >= 0">
        {{ q.name }}
      </div>
      <div
        class="escalas"
        *ngIf="q.type == 'escala' && step >= 0"
        [ngClass]="{
          'xsel-0': asks[0] === 0,
          'xsel-1': asks[1] === 1,
          'xsel-2': asks[2] === 2,
          'xsel-3': asks[3] === 3,
          'xsel-4': asks[4] === 4,
          'xsel-5': asks[5] === 5,
          'xsel-6': asks[6] === 6,
          'xsel-7': asks[7] === 7,
          'xsel-8': asks[8] === 8,
          'xsel-9': asks[9] === 9,
          'xsel-10': asks[10] === 10
        }"
      >
        <div
          class="item"
          [ngClass]="{ active: asks[10] == 10 }"
          (click)="update(10, { value: 10 })"
        >
          <p>10</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[9] == 9 }"
          (click)="update(9, { value: 9 })"
        >
          <p>9</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[8] == 8 }"
          (click)="update(8, { value: 8 })"
        >
          <p>8</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[7] == 7 }"
          (click)="update(7, { value: 7 })"
        >
          <p>7</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[6] == 6 }"
          (click)="update(6, { value: 6 })"
        >
          <p>6</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[5] == 5 }"
          (click)="update(5, { value: 5 })"
        >
          <p>5</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[4] == 4 }"
          (click)="update(4, { value: 4 })"
        >
          <p>4</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[3] == 3 }"
          (click)="update(3, { value: 3 })"
        >
          <p>3</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[2] == 2 }"
          (click)="update(2, { value: 2 })"
        >
          <p>2</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[1] == 1 }"
          (click)="update(1, { value: 1 })"
        >
          <p>1</p>
        </div>
        <div
          class="item"
          [ngClass]="{ active: asks[0] == 0 }"
          (click)="update(0, { value: 0 })"
        >
          <p>0</p>
        </div>
      </div>
      <div class="open-answer" *ngIf="q.type == 'escala' && step >= 0.5">
        <div *ngIf="low">
          <div class="ask">
            <p *ngIf="lang == 'esp'">¿Por qué no nos recomendarías?</p>
            <p *ngIf="lang == 'por'">Por quê você não recomendaria GO fit?</p>
            <p *ngIf="lang == 'eng'">Why wouldn't you recommend us?</p>
          </div>
          <textarea
            name=""
            [(ngModel)]="commit"
            (change)="valuechange($event)"
          ></textarea>
        </div>
        <div *ngIf="mid">
          <div class="ask">
            <p *ngIf="lang == 'esp'">
              ¿Qué aspectos deberíamos mejorar para que nos recomendaras más?
            </p>
            <p *ngIf="lang == 'eng'">
              What aspects should we improve so that you would recommend us
              more?
            </p>
            <p *ngIf="lang == 'por'">
              Quais são os aspectos que deveríamos melhorar para recomendar-nos
              mais?
            </p>
          </div>
          <textarea
            name=""
            [(ngModel)]="commit"
            (ngModelChange)="valuechange($event)"
          ></textarea>
        </div>
        <div *ngIf="higth">
          <div class="ask">
            <p *ngIf="lang == 'esp'">
              ¿Qué aspectos destacas cuando nos recomiendas?
            </p>
            <p *ngIf="lang == 'eng'">
              What aspects do you highlight when you recommend us?
            </p>
            <p *ngIf="lang == 'por'">
              Quais são os aspectos que você destaca quando recomenda GO fit?
            </p>
          </div>
          <textarea
            [(ngModel)]="commit"
            (ngModelChange)="valuechange($event)"
          ></textarea>
        </div>
        <a (click)="valuechange('')" *ngIf="lang == 'esp'" class="light"
          >No quiero responder</a
        >
        <a (click)="valuechange('')" *ngIf="lang == 'eng'" class="light"
          >I do not want to answer</a
        >
        <a (click)="valuechange('')" *ngIf="lang == 'por'" class="light"
          >Eu não quero responder</a
        >
      </div>

      <div class="zones" *ngIf="q.type == 'checkbox' && step >= 1">
        <div class="ask {{ q.type }}">
          {{ q.name }}
        </div>
        <div *ngFor="let z of zone">
          <mat-checkbox
            *ngIf="lang == z.idioma"
            [(ngModel)]="z.isChecked"
            (change)="someComplete(z.id)"
            >{{ z.text }}
          </mat-checkbox>
        </div>
      </div>
      <div class="zones" *ngIf="q.type == 'checkbox-child' && step >= 1 && showChild == true">
        <div class="ask {{ q.type }}">
          {{ q.name }}
        </div>
        <div *ngFor="let z of zoneFitness">
          <mat-checkbox
            *ngIf="lang == z.idioma"
            [(ngModel)]="z.isChecked"
            (change)="someComplete2(z.id)"
            >{{ z.text }}
          </mat-checkbox>
        </div>
      </div>
      <div *ngIf="q.type == 'radio' && step >= 2">
        <div class="ask {{ q.type }}">
          {{ q.name }}
        </div>
        <mat-radio-group
          aria-label="Select an option"
          (change)="updateRadio($event)"
          class="radios"
          *ngIf="lang == 'esp' || lang == 'por'"
          [(ngModel)]="selectRecomendation"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let r of optionRecomendation"
            [value]="r"
          >
            {{ r }}
          </mat-radio-button>
        </mat-radio-group>

        <mat-radio-group
          aria-label="Select an option"
          (change)="updateRadio($event)"
          [(ngModel)]="selectRecomendation"
          class="radios"
          *ngIf="lang == 'eng'"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let r of optionRecomendation1"
            [value]="r"
          >
            {{ r }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="q.type == 'radio2' && step >= 3">
        <div class="ask {{ q.type }}">
          {{ q.name }}
        </div>
        <mat-radio-group
          aria-label="Select an option"
          (change)="updateRadio2($event)"
          [(ngModel)]="selectObjetive"
          class="radios"
          *ngIf="lang == 'esp'"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let o of objective"
            [value]="o"
          >
            {{ o }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
          aria-label="Select an option"
          (change)="updateRadio2($event)"
          [(ngModel)]="selectObjetive"
          class="radios"
          *ngIf="lang == 'eng'"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let o of objective2"
            [value]="o"
          >
            {{ o }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
          aria-label="Select an option"
          (change)="updateRadio2($event)"
          [(ngModel)]="selectObjetive"
          class="radios"
          *ngIf="lang == 'por'"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let o of objective1"
            [value]="o"
          >
            {{ o }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <!-- <button
      (click)="save()"
      class="float-right"
      *ngIf="step >= 3"
      mat-flat-button
      color="accent"
    >
      Guardar
    </button> -->
  </mat-card-content>
</mat-card>
