<div *ngIf="loading == true" class="loading">
  <mat-spinner
    class="spinner-custom"
    [diameter]="55"
    color="accent"
  ></mat-spinner>
</div>
<div class="langs">
    <div class="flag" (click)="changeFlag('esp')">
        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Bandera_de_Espa%C3%B1a_%28nuevo_dise%C3%B1o%29.svg"
            alt="">
    </div>
    <div class="flag" (click)="changeFlag('por')">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/1200px-Flag_of_Portugal.svg.png"
            alt="">
    </div>
    <div class="flag" (click)="changeFlag('eng')">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_United_Kingdom.svg/2560px-Flag_of_the_United_Kingdom.svg.png"
            alt="">
    </div>
</div>
<div *ngIf="flag === false">

    <mat-card class="card-primary mat-elevation-z" *ngIf="lang =='esp'">
        <mat-card-content>
            <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
                alt="logo Gofit" class="logo">

            <mat-card-title class="title">
                Lo sentimos no hemos podido obtener informacion de su encuesta.
            </mat-card-title>
            <p>Compruebe que esta accediendo a la encuesta mas reciente en su correo electronico. Si el problema
                continua puede comunicarse con el soporte tecnico de esta aplicacion.</p>

        </mat-card-content>
        <mat-card-footer>
            <button>Entiendo</button>
        </mat-card-footer>
    </mat-card>
    <mat-card *ngIf="lang =='eng'">
        <mat-card-title class="title">
            An error has occurred.
        </mat-card-title>
        <mat-card-content>
            <p>This can happen because you are entering an expired or wrong link.</p>
        </mat-card-content>
    </mat-card>
</div>
<mat-card class="card-primary mat-elevation-z" *ngIf="flag == true">

    <mat-card-content *ngIf="lang =='esp'">
        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <h2>Bienvenido <span class="capitalize">{{survey.relationCustomer.nombre}} {{survey.relationCustomer.apellidos}} </span></h2>
        <p>Haz click para completar esta encuesta, será un proceso rápido y sencillo.</p>
        <button mat-flat-button class="full-bt" (click)="startSurvey();" color="accent">Comenzar</button>
    </mat-card-content>
    <mat-card-content *ngIf="lang =='eng'">
        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <p>Welcome <span class="capitalize">{{survey.relationCustomer.nombre}} {{survey.relationCustomer.apellidos}} </span>, click to complete this survey, it will
            be
            a quick and easy process.</p>
        <button *ngIf="lang =='eng'" class="full-bt" mat-flat-button (click)="startSurvey();" color="accent">Start</button>
    </mat-card-content>
    <mat-card-content *ngIf="lang =='por'">
        <img src="https://go-fit.es/wp-content/uploads/2022/04/Logo-GO-fit-LIFE-SCIENCE-TECH_RGB_02-1.svg"
            alt="logo Gofit" class="logo">
        <h2>Bem-vindo <span class="capitalize">{{survey.relationCustomer.nombre}} {{survey.relationCustomer.apellidos}} </span></h2>
        <p>Clique para preencher esta pesquisa, será um processo rápido e fácil.</p>
        <button mat-flat-button class="full-bt" (click)="startSurvey();" color="accent">Começar</button>
    </mat-card-content>
</mat-card>
