import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-thanks-promotor",
  templateUrl: "./thanks-promotor.component.html",
  styleUrls: ["./thanks-promotor.component.scss"],
})
export class ThanksPromotorComponent implements OnInit {
  lang;
  constructor() {
    this.lang = localStorage.getItem("idioma");
  }
  ngOnInit(): void {}
}
